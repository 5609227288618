import * as React from 'react'
import { AsyncButton } from '../../components/async-button/async-button.component'
import { AppService } from '../../services'
import { PageHeader } from '../../components/page-header/page-header'
import NotificationList from '../../components/notification-list/notification-list'
import { getPathEntityId } from '../../_utils/get-path-entity-id'
import { AppState } from '../../stores/app'
import { useService } from '@pushly/aqe/lib/hooks'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { AbilityAction } from '../../enums/ability-action.enum'
import { asCaslSubject, CurrentUserCan } from '../../stores/app-ability'
import SimpleTabbedView from '../../components/simple-tabbed-view/simple-tabbed-view'
import { NotificationListTypes } from '../../components/notification-list/enums'
import NotificationTemplateList from '../notifications/named-notification-template/notification-template-list'
import { FeedsDataView } from '../../components/feeds/feeds-data-view'

const Notifications = (props) => {
    const appState = useService(AppState)
    const appService = useService(AppService)

    const pathOrgId = getPathEntityId('organization')
    const pathDomainId = getPathEntityId('domain')

    /**
     * Ensure injected props are passed down
     * for history container access
     */
    const listProps: any = { ...props }
    let permissionEntity: SubjectEntity = SubjectEntity.NOTIFICATION
    let permissionConstraints: any = {}

    if (pathOrgId) {
        listProps.level = 'org'
        listProps.orgId = pathOrgId

        permissionEntity = SubjectEntity.ORG_NOTIFICATION
        permissionConstraints.accountId = pathOrgId
    } else {
        listProps.level = 'domain'
        listProps.domainId = pathDomainId

        permissionConstraints.domainId = pathDomainId
    }

    const newNotifPath = appService.routeWithin(listProps.level, '/notifications/new', true)
    const titleEntity = pathOrgId ? 'Multi-Domain Notifications' : 'Notifications'

    let orgName
    if (pathOrgId && appState.currentDomain?.accountId === pathOrgId) {
        orgName = appState.currentDomain.accountName
    }

    const notificationListTabs = [
        {
            tabClassName: 'notification-list-tab',
            component: SimpleTabbedView.wrapComponentTab(NotificationList, {
                tabName: null,
                tabLabel: 'Notifications',
            }),
            props: () => {
                return {
                    ...listProps,
                    listType: NotificationListTypes.NOTIFICATION,
                    hideAutoRefreshOptions: true,
                }
            },
        },
    ]
    const viewActions: any[] = []

    if (!pathOrgId) {
        notificationListTabs.push(
            {
                tabClassName: 'draft-list-tab',
                component: SimpleTabbedView.wrapComponentTab(NotificationList, {
                    tabName: 'drafts',
                    tabLabel: 'Drafts',
                }),
                props: () => {
                    return {
                        ...listProps,
                        listType: NotificationListTypes.DRAFT,
                        hideAutoRefreshOptions: true,
                    }
                },
            },
            {
                tabClassName: 'template-list-tab',
                component: SimpleTabbedView.wrapComponentTab(
                    NotificationTemplateList,
                    {
                        tabName: 'templates',
                        tabLabel: 'Templates',
                    },
                    [pathDomainId],
                ),
                props: () => {
                    return {
                        ...listProps,
                        hideAutoRefreshOptions: true,
                    }
                },
            },
            {
                tabClassName: 'feeds-list-tab',
                component: SimpleTabbedView.wrapComponentTab(
                    FeedsDataView,
                    {
                        tabName: 'feeds',
                        tabLabel: 'Feeds',
                    },
                    [pathDomainId],
                ),
                props: () => {
                    return {
                        ...listProps,
                        domain: appState.currentDomain,
                        hideAutoRefreshOptions: true,
                    }
                },
            },
        )

        const newTemplatePath = appService.routeWithin(listProps.level, '/notifications/templates/new', true)
        viewActions.push({
            text: 'Create Template',
            onClick: () => appService.route(newTemplatePath),
            altHref: () => appService.routeWithinDomain(newTemplatePath, true),
        })
    }

    return (
        <div className="notification-list">
            <PageHeader
                title={titleEntity}
                append={orgName}
                action={
                    <CurrentUserCan
                        do={AbilityAction.CREATE}
                        on={asCaslSubject(permissionEntity, permissionConstraints)}
                    >
                        <AsyncButton
                            className="new-notification-button"
                            type="primary"
                            onClick={() => appService.route(newNotifPath)}
                            altHref={newNotifPath}
                            actions={viewActions}
                        >
                            <span>Schedule Notification</span>
                        </AsyncButton>
                    </CurrentUserCan>
                }
            />

            <SimpleTabbedView pathStyle="slash" animated={false} tabs={notificationListTabs} />
        </div>
    )
}

export default Notifications
