import * as React from 'react'
import * as querystring from 'query-string'
import { action } from 'mobx'
import axios from 'axios'
import { Container, Singleton } from 'typescript-ioc/es5'
import { simpleNotification, fetchUserPermissions, shouldUseLegacyAuthHeader } from '../_utils/utils'
import { AppService } from './app'
import { UserType } from '../enums/user-type'
import { UserDto } from '../dtos/user'
import { StatusType } from '../enums/status-type'
import { AppState } from '../stores/app'
import {
    type INewUserFormOptions,
    NewUserRequestDto,
    UserConfirmationRequestDto,
    UserEditRequestDto,
    InviteSuccessMessage,
} from '../features/users/index'
import type { IConfirmUserFormOptions, IPasswordResetFormOptions } from '../features/pre-auth/index'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { AddUserSuccessMessage } from '../components/user-responses-messages/add-user-success-message'
import { type IServiceApiResponse } from '../interfaces/service-api-response'
import type IApiCallOptions from '../interfaces/api-call-options'
import aqe from '@pushly/aqe'
import { PushlyCookie } from '../enums/pushly-cookie.enum'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

interface IEditUserFormOptions {
    name: string
    password: string
    passwordVerify: string
    accounts?: any[]
}

export enum UserTokenType {
    INVITATION = 'INVITATION',
    PASSWORD_RESET = 'PASSWORD_RESET',
}

type UserTokenRefreshResponse = IServiceApiResponse<UserDto> & { token: string }

@Singleton
export class UserService {
    private appState: AppState
    private appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public async fetchAllAccountUsers(
        accountId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<UserDto[]>> {
        let ok = false
        let users: UserDto[] = []
        let meta = {}

        const options = querystring.stringify(opts.query || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/users?${options}`

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts.cancellationKey,
            )

            const { data, ...resMeta } = req.data

            ok = true
            users = data.map(UserDto.fromApiResponse)
            meta = resMeta
        } catch (_err) {}

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }
        return { ok, data: users, meta }
    }

    public async fetchAllDomainUsers(
        domainId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<UserDto[]>> {
        let ok = false
        let users: UserDto[] = []
        let meta = {}

        const options = querystring.stringify(opts.query || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/users?${options}`

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts.cancellationKey,
            )

            const { data, ...resMeta } = req.data

            ok = true
            users = data.map(UserDto.fromApiResponse)
            meta = resMeta
        } catch (_err) {}

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }
        return { ok, data: users, meta }
    }

    public async fetchById(userId: any, opts: IApiCallOptions = {}): Promise<IServiceApiResponse<UserDto | undefined>> {
        let ok = false
        let user: UserDto | undefined
        let meta = {}

        const options = querystring.stringify(opts.query || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/users/${userId}?${options}`

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts.cancellationKey,
            )

            ok = true
            user = UserDto.fromApiResponse(req.data.data)
            meta = req.data.meta
        } catch (_err) {}

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }
        return { ok, data: user, meta }
    }

    public async fetchUserById(
        userId: number,
        options?: {
            muteErrors?: boolean
        },
    ): Promise<UserDto | undefined> {
        let user: UserDto | undefined

        try {
            const request = await axios.get(`${aqe.defaults.publicApiDomain}/users/${userId}`)
            user = UserDto.fromApiResponse(request.data.data)
        } catch (error) {
            if (!options?.muteErrors) {
                handleResponseErrorMessage(error)
            }
        }

        return user
    }

    public async fetchUserPermissions(userId: number) {
        return fetchUserPermissions(userId)
    }

    public async add(userDto: any, opts: IApiCallOptions = {}): Promise<IServiceApiResponse<UserDto>> {
        let ok = false
        let user: UserDto
        let meta = {}

        const serviceURL = `${aqe.defaults.publicApiDomain}/users`

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: serviceURL,
                    data: userDto,
                },
                opts.cancellationKey,
            )

            ok = true

            const { data: responseData, ...responseMeta } = req.data
            user = UserDto.fromApiResponse(responseData)
            meta = req.data.meta || responseMeta

            simpleNotification(
                'success',
                <AddUserSuccessMessage
                    type="invite"
                    responseData={req.data}
                    message={'The user was created and sent an invitation email.'}
                />,
                0,
            )
        } catch (err) {
            handleResponseErrorMessage(err, {
                fallbackMessage: 'An error has occurred.',
            })
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }
        return { ok, data: user!, meta }
    }

    public async updateById(
        userId: number,
        update: any,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<UserDto | undefined>> {
        let ok = false
        let user: UserDto | undefined
        let meta = {}

        const serviceURL = `${aqe.defaults.publicApiDomain}/users/${userId}`

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: serviceURL,
                    data: update,
                },
                opts.cancellationKey,
            )

            const { data: reqData, ...reqMeta } = req.data

            ok = true
            user = UserDto.fromApiResponse(reqData)
            meta = reqMeta
        } catch (error) {
            handleResponseErrorMessage(error, {
                fallbackMessage: 'The user could not be updated at this.',
            })
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }
        return { ok, data: user, meta }
    }

    public async updateUserById(userId: number, userOptions: IEditUserFormOptions): Promise<boolean> {
        const requestDto = new UserEditRequestDto()

        if (userOptions.name) {
            requestDto.name = userOptions.name
        }
        if (userOptions.password) {
            requestDto.password = userOptions.password
        }
        if (userOptions.accounts) {
            requestDto.accounts = userOptions.accounts
        }

        this.appService.setModuleLoading()

        try {
            await axios.patch(`${aqe.defaults.publicApiDomain}/v4/users/${userId}`, userOptions)
            simpleNotification('success', 'User was successfully updated')
            this.appService.unsetModuleLoading()
            return true
        } catch (error) {
            handleResponseErrorMessage(error, {
                fallbackMessage: 'We could not update your user at this time.',
            })

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async createNewUser(newUserOptions: INewUserFormOptions): Promise<boolean> {
        const requestDto = new NewUserRequestDto()

        requestDto.userType = newUserOptions.isInternalUser ? UserType.INTERNAL : UserType.EXTERNAL
        requestDto.accounts = newUserOptions.accounts

        requestDto.email = newUserOptions.email
        if (newUserOptions.isInternalUser) {
            requestDto.email = `${requestDto.email}@pushly.com`
        }

        this.appService.setModuleLoading()

        let created = false
        try {
            const response = await axios.post(`${aqe.defaults.publicApiDomain}/v4/users`, requestDto)
            const data = response.data

            created = true
            simpleNotification(
                'success',
                <InviteSuccessMessage responseData={data} message={'The user has been sent an invitation email.'} />,
                0,
            )
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.appService.unsetModuleLoading()

        return created
    }

    public async registerPuuid(userId: number, domainId: number, puuid: string): Promise<boolean> {
        this.appService.setModuleLoading()

        let registered = false
        try {
            await axios.post(`${aqe.defaults.publicApiDomain}/users/${userId}/register-puuid`, {
                domainId,
                puuid,
            })

            registered = true
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.appService.unsetModuleLoading()

        return registered
    }

    public async confirmUserToken(token: string, type: UserTokenType): Promise<any> {
        const requestDto: any = {
            type,
            token,
        }

        let confirmation: any
        try {
            const response = await axios.post(`${aqe.defaults.publicApiDomain}/users/confirm`, requestDto)

            confirmation = response.data.data
        } catch (error) {
            if (isAxiosCancellation(error) || !error.response || !error.response.data) {
                console.error(error)
                throw { message: 'Unable to verify password reset token at this time.' }
            }

            throw error.response?.data
        }

        return confirmation
    }

    public async processNewUserConfirmation(
        token: string,
        userId: number,
        userConfirmationOptions: IConfirmUserFormOptions,
    ): Promise<any | void> {
        const requestDto = new UserConfirmationRequestDto()

        requestDto.token = token
        requestDto.name = userConfirmationOptions.name
        requestDto.password = userConfirmationOptions.password

        try {
            const response = await axios.patch<UserTokenRefreshResponse>(
                `${aqe.defaults.publicApiDomain}/v4/users/confirm/${userId}`,
                requestDto,
            )
            const data = response.data

            if (shouldUseLegacyAuthHeader()) {
                window.localStorage.setItem(PushlyCookie.PLATFORM_COOKIE, response.data.token)
            }

            await this.processAuthenticatedUserResponse(data)
        } catch (error) {
            return error.response.data
        }
    }

    public async processUserPasswordReset(
        token: string,
        userId: number,
        userConfirmationOptions: IPasswordResetFormOptions,
    ): Promise<any | void> {
        const requestDto = new UserConfirmationRequestDto()

        requestDto.token = token
        requestDto.password = userConfirmationOptions.password
        requestDto.confirmationRequired = false

        try {
            const response = await axios.patch<UserTokenRefreshResponse>(
                `${aqe.defaults.publicApiDomain}/v4/users/confirm/${userId}`,
                requestDto,
            )
            const data = response.data

            if (shouldUseLegacyAuthHeader()) {
                window.localStorage.setItem(PushlyCookie.PLATFORM_COOKIE, response.data.token)
            }

            await this.processAuthenticatedUserResponse(data)
        } catch (error) {
            throw error
        }
    }

    public async processAuthenticatedUserResponse(response: any) {
        const { data: userData } = response

        this.appState.currentUser = UserDto.fromApiResponse(userData)

        await this.appState.fetchUserDependencies()
        this.appState.isAuthenticated = true
    }

    public async fetchUsersForCurrentDomain(): Promise<UserDto[]> {
        const domainId = this.appState.currentDomain!.id

        const response = await axios.get(`${aqe.defaults.publicApiDomain}/domains/${domainId}/users/`)
        const responseData = response.data
        return responseData.data.map((user: any) => UserDto.fromApiResponse(user))
    }

    @action.bound
    public async enableUserId(userId: number): Promise<UserDto | void> {
        this.appService.setModuleLoading()

        try {
            const response = await axios.patch(`${aqe.defaults.publicApiDomain}/v4/users/${userId}`, {
                status: StatusType.ACTIVE.name,
            })

            this.appService.unsetModuleLoading()
            simpleNotification('success', 'User was successfully enabled')
            return response.data.data
        } catch {
            this.appService.unsetModuleLoading()
            simpleNotification('error', 'We could not enable the user at this time')
        }
    }

    @action.bound
    public async disableUserId(userId: number): Promise<UserDto | void> {
        this.appService.setModuleLoading()

        try {
            const response = await axios.patch(`${aqe.defaults.publicApiDomain}/v4/users/${userId}`, {
                status: StatusType.DISABLED.name,
            })

            this.appService.unsetModuleLoading()
            simpleNotification('success', 'User was successfully disabled')
            return response.data.data
        } catch {
            this.appService.unsetModuleLoading()
            simpleNotification('error', 'We could not enable the user at this time')
        }
    }

    @action.bound
    public async triggerInviteForUserId(
        userId: number,
        accountId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<UserDto>> {
        let ok = false
        let user: UserDto
        let meta = {}

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch('patch', {
                url: `${aqe.defaults.publicApiDomain}/v4/users/${userId}/re-invite`,
                data: {
                    accountId,
                },
            })

            ok = true

            const { data: responseData, ...responseMeta } = req.data
            user = UserDto.fromApiResponse(responseData)
            meta = req.data.meta || responseMeta

            simpleNotification(
                'success',
                <AddUserSuccessMessage
                    type="reinvite"
                    responseData={req.data}
                    message={'The user has been sent an invitation email.'}
                />,
                0,
            )
        } catch (_err) {
            simpleNotification('error', 'We could not re-process the invitation at this time')
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }
        return { ok, data: user!, meta }
    }

    @action.bound
    public async triggerPasswordResetForUserId(
        userId: number,
        accountId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<UserDto>> {
        let ok = false
        let user: UserDto
        let meta = {}

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch('patch', {
                url: `${aqe.defaults.publicApiDomain}/v4/users/${userId}/reset-password`,
                data: {
                    accountId,
                },
            })

            ok = true

            const { data: responseData, ...responseMeta } = req.data
            user = UserDto.fromApiResponse(responseData)
            meta = req.data.meta || responseMeta

            simpleNotification(
                'success',
                <AddUserSuccessMessage
                    type="password"
                    responseData={req.data}
                    message="The user has been sent a password reset email."
                />,
                0,
            )
        } catch (_err) {
            simpleNotification('error', "We could not reset the user's password at this time")
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }
        return { ok, data: user!, meta }
    }
}
