import { Domain } from '@pushly/models/lib/structs/domain'
import { DomainBuilder } from '@pushly/models/lib/builders/domain-builder'
import { DomainDto, IDomainFrequencyCap, IDomainFrequencyCaps } from '../dtos/domain'
import { NotificationAction } from '@pushly/models/lib/structs/notification-action'
import {
    IAbstractNativeApnsSendIntegration,
    IAbstractNativeFcmSendIntegration,
    IWebApnsSendIntegration,
} from '../interfaces/send-integrations'
import { WebApnsSendIntegrationConfig } from '@pushly/models/lib/structs/send-integration-configurations/web-apns-send-integration-config'
import { NativeApnsSendIntegrationConfig } from '@pushly/models/lib/structs/send-integration-configurations/native-apns-send-integration-config'
import { NativeFcmSendIntegrationConfig } from '@pushly/models/lib/structs/send-integration-configurations/native-fcm-send-integration-config'
import { NotificationActionBuilder } from '@pushly/models/lib/builders/notification-action-builder'
import { WebApnsSendIntegrationConfigBuilder } from '@pushly/models/lib/builders/web-apns-send-integration-config-builder'
import {
    NativeP12ApnsConfiguration,
    NativeP8ApnsConfiguration,
} from '../models/send-integration-configurations/native-apns-configurations.model'
import { isJSONObjectType } from './object'
import {
    NativeLegacyFcmConfiguration,
    NativeV1FcmConfiguration,
} from '../models/send-integration-configurations/native-fcm-configurations.model'
import { FcmSendIntegrationApiVersion } from '@pushly/models/lib/interfaces/send-integrations'
import { NativeApnsSendIntegrationConfigBuilder } from '@pushly/models/lib/builders/native-apns-send-integration-config-builder'
import { NativeFcmSendIntegrationConfigBuilder } from '@pushly/models/lib/builders/native-fcm-send-integration-config-builder'
import { SendIntegrationPrivateKeyType } from '../enums/send-integration-private-key-type.enum'
import { DomainUtmParams } from '@pushly/models/lib/structs/domain-utm-params'
import { DomainUtmParamsBuilder } from '@pushly/models/lib/builders/domain-utm-params-builder'
import { DomainGlobalPromptSettings } from '@pushly/models/lib/structs/domain-global-prompt-settings'
import { GlobalPromptReferrerConditions } from '@pushly/models/lib/structs/global-prompt-referrer-conditions'
import { DomainEcommConfiguration } from '@pushly/models/lib/structs/domain-ecomm-configuration'
import { DomainFrequencyCap } from '@pushly/models/lib/structs/domain-frequency-cap'
import { DomainFrequencyCaps } from '@pushly/models/lib/structs/domain-frequency-caps'
import { DomainFrequencyCapsBuilder } from '@pushly/models/lib/builders/domain-frequency-caps-builder'
import { DomainFrequencyCapBuilder } from '@pushly/models/lib/builders/domain-frequency-cap-builder'
import { parseArrayOrNull } from './array'
import { SendIntegrationPrivateKey } from '@pushly/models/lib/structs/send-integration-configurations/send-integration-private-key'
import { SendIntegrationPushPackage } from '@pushly/models/lib/structs/send-integration-configurations/send-integration-push-package'
import { NotificationActionType } from '../interfaces/notification-action'
import { NotificationFrequencyCapsConfig } from '@pushly/models/lib/structs/notification-frequency-caps-config'
import { NotificationFrequencyCapsConfigBuilder } from '@pushly/models/lib/builders/notification-frequency-caps-config-builder'
import { NotificationNamedTemplate } from '@pushly/models/lib/structs'
import { NotificationNamedTemplateBuilder } from '@pushly/models/lib/builders'
import { NotificationContentTemplate } from '@pushly/models/lib/structs/notification-content-template'
import { NotificationContentTemplateBuilder } from '@pushly/models/lib/builders/notification-content-template-builder'
import { NotificationDefaultContent } from '@pushly/models/lib/structs/notification-default-content'
import { NotificationContentBase } from '@pushly/models/lib/structs/notification-content-base'
import { NotificationIOSContent } from '@pushly/models/lib/structs/notification-ios-content'
import { NotificationIOSContentBuilder } from '@pushly/models/lib/builders/notification-ios-content-builder'
import { NotificationDefaultContentBuilder } from '@pushly/models/lib/builders/notification-default-content-builder'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

// Hydration Exception

type Class = new (...args: any[]) => any
class HydrationException extends Error {
    public constructor(value: any, model: Class, message?: string) {
        super(message ?? `${model.name} cannot parse and hydrate from parameter type passed: ${JSON.stringify(value)}`)
    }
}

// Domain Model Upgrade Utilities

export function hydrateDomainModel(domain: DomainDto | Domain | DomainBuilder | any): Domain {
    const builder = Domain.Builder
    if (domain instanceof Domain || domain instanceof DomainBuilder) {
        builder
            .setId(domain.id)
            .setName(domain.name)
            .setAccountId(domain.accountId)
            .setAccountName(domain.accountName)
            .setAccountFlags(domain.accountFlags)
            .setAddedNotificationQueryParams(domain.addedNotificationQueryParams)
            .setCreatedAt(domain.createdAt)
            .setCreatedBy(domain.createdBy)
            .setCreatedByUsername(domain.createdByUsername)
            .setCollapseKeyType(domain.collapseKeyType)
            .setCurrency(domain.currency!)
            .setCurrencyCode(domain.currencyCode)
            .setDefaultNotificationActionLabels(domain.defaultNotificationActionLabels)
            .setDefaultNotificationActions(
                domain.defaultNotificationActions?.map((action) => hydrateNotificationActionModelFromDto(action)) ??
                    null,
            )
            .setDefaultNotificationTtlSeconds(domain.defaultNotificationTtlSeconds)
            .setDefaultIconUrl(domain.defaultIconUrl)
            .setDefaultBadgeUrl(domain.defaultBadgeUrl)
            .setDeliveryThrottleMaxSeconds(domain.deliveryThrottleMaxSeconds ?? 0)
            .setDisplayMeta(domain.displayMeta)
            .setDisplayName(domain.displayName)
            .setDomainKey(domain.domainKey)
            .setFcmServerKey(domain.fcmServerKey)
            .setFlags(Array.from(domain.flags))
            .setFrequencyCaps(hydrateDomainFrequencyCaps(domain.frequencyCaps))
            .setLandingUrlDomainWhitelist(domain.landingUrlDomainWhitelist)
            .setApnsConfiguration(hydrateWebApnsConfigurationModel(domain.apnsConfiguration))
            .setNativeApnsConfiguration(hydrateNativeApnsConfigurationModel(domain.nativeApnsConfiguration))
            .setNativeFcmConfiguration(hydrateNativeFcmConfigurationModel(domain.nativeFcmConfiguration))
            .setNotificationAutoSuggestFields(domain.notificationAutoSuggestFields)
            .setNotificationFrequencyCaps(domain.notificationFrequencyCaps)
            .setProdDomainId(domain.prodDomainId)
            .setRoutingKey(domain.routingKey)
            .setSdkEventOnlyDomains(Array.from(domain.sdkEventOnlyDomains))
            .setSenderId(domain.senderId)
            .setStatus(domain.status)
            .setStatusId(domain.statusId)
            .setTimeZone(domain.timeZone)
            .setUpdatedAt(domain.updatedAt)
            .setUpdatedBy(domain.updatedBy)
            .setUpdatedByUsername(domain.updatedByUsername)
            .setUtmParams(hydrateUtmParams(domain.utmParams))
            .setVapidPublicKey(domain.vapidPublicKey)
            .setWhitelistDomains(parseArrayOrNull(domain.whitelistDomains))

        if (domain.globalPromptSettings) {
            builder.setGlobalPromptSettings(domain.globalPromptSettings)
        }
        if (domain.ecommConfig) {
            builder.setEcommConfig(domain.ecommConfig)
        }
        if (domain.mediaQuality) {
            builder.setMediaQuality(domain.mediaQuality)
        }
    } else {
        // abstract if to function: isActualObject
        if (!isJSONObjectType(domain)) {
            throw new HydrationException(domain, Domain)
        }
        builder
            .setId(domain.id)
            .setName(domain.name)
            .setAccountId(domain.accountId)
            .setAccountName(domain.accountName)
            .setAccountFlags(domain.accountFlags)
            .setAddedNotificationQueryParams(domain.addedNotificationQueryParams)
            .setCreatedAt(domain.createdAt)
            .setCreatedBy(domain.createdBy)
            .setCreatedByUsername(domain.createdByUsername)
            .setCollapseKeyType(domain.collapseKeyType)
            .setCurrency(domain.currency!)
            .setCurrencyCode(domain.currencyCode)
            .setDefaultNotificationActionLabels(domain.defaultNotificationActionLabels)
            .setDefaultNotificationActions(
                domain.defaultNotificationActions?.map((action) => hydrateNotificationActionModelFromDto(action)) ??
                    null,
            )
            .setDefaultNotificationTtlSeconds(domain.defaultNotificationTtlSeconds)
            .setDefaultIconUrl(domain.defaultIconUrl)
            .setDefaultBadgeUrl(domain.defaultBadgeUrl)
            .setDeliveryThrottleMaxSeconds(domain.deliveryThrottleMaxSeconds ?? 0)
            .setDisplayMeta(domain.displayMeta)
            .setDisplayName(domain.displayName)
            .setDomainKey(domain.domainKey)
            .setFcmServerKey(domain.fcmServerKey)
            .setFlags(domain.flags)
            .setFrequencyCaps(hydrateDomainFrequencyCaps(domain.frequencyCaps))
            .setLandingUrlDomainWhitelist(domain.landingUrlDomainWhitelist)
            .setApnsConfiguration(hydrateWebApnsConfigurationModel(domain.apnsConfiguration))
            .setNativeApnsConfiguration(hydrateNativeApnsConfigurationModel(domain.nativeApnsConfiguration))
            .setNativeFcmConfiguration(hydrateNativeFcmConfigurationModel(domain.nativeFcmConfiguration))
            .setNotificationAutoSuggestFields(domain.notificationAutoSuggestFields)
            .setNotificationFrequencyCaps(
                domain.notificationFrequencyCaps?.map((cap) => hydrateNotificationFrequencyCaps(cap)) ?? null,
            )
            .setProdDomainId(domain.prodDomainId)
            .setRoutingKey(domain.routingKey)
            .setSdkEventOnlyDomains(domain.sdkEventOnlyDomains)
            .setSenderId(domain.senderId)
            .setStatus(domain.status)
            .setStatusId(domain.statusId)
            .setTimeZone(domain.timezone)
            .setUpdatedAt(domain.updatedAt)
            .setUpdatedBy(domain.updatedBy)
            .setUpdatedByUsername(domain.updatedByUsername)
            .setUtmParams(hydrateUtmParams(domain.utmParams))
            .setVapidPublicKey(domain.vapidPublicKey)
            .setWhitelistDomains(domain.whitelistDomains)

        if (domain.globalPromptSettings?.referrers) {
            const referrerConditions = domain.globalPromptSettings.referrers
            const globalPromptSettings = DomainGlobalPromptSettings.Builder.setReferrers(
                GlobalPromptReferrerConditions.Builder.setEnabled(referrerConditions.enabled ?? false)
                    .setPersistTtlSeconds(referrerConditions.persistTtlSeconds)
                    .setWhenSelf(referrerConditions.whenSelf)
                    .setWhenBlank(referrerConditions.whenBlank)
                    .setAllowed(referrerConditions.allowed)
                    .setBlocked(referrerConditions.blocked)
                    .build(),
            ).build()

            builder.setGlobalPromptSettings(globalPromptSettings)
        } else {
            builder.setGlobalPromptSettings(null)
        }

        if (domain.ecommConfig) {
            const config = domain.ecommConfig
            const ecommConfig = DomainEcommConfiguration.Builder.setItemType(config.itemType)
                .setMaxAbandonedItemAgeDays(config.maxAbandonedItemAgeDays)
                .setClickAttributionWindowHours(config.clickAttributionWindowHours)
                .setImpressionAttributionWindowHours(config.clickImpressionsAttributionWindowHours)
                .build()

            builder.setEcommConfig(ecommConfig)
        }
        if (domain.mediaQuality) {
            builder.setMediaQuality(domain.mediaQuality)
        }
    }

    return builder.build()
}

export function hydrateNotificationFrequencyCaps(
    cap: NotificationFrequencyCapsConfig | NotificationFrequencyCapsConfigBuilder | any,
) {
    if (cap === null) {
        return null
    }

    const builder = NotificationFrequencyCapsConfig.Builder
    if (!isJSONObjectType(cap)) {
        throw new HydrationException(cap, NotificationFrequencyCapsConfig)
    }

    builder.setValue(cap.value).setDurationMinutes(cap.durationMinutes).setDisplayMetric(cap.displayMetric)

    return builder.build()
}

export function hydrateDomainFrequencyCaps(
    caps: DomainFrequencyCaps | DomainFrequencyCapsBuilder | IDomainFrequencyCaps | any,
) {
    if (caps === null) {
        return null
    }

    const builder = DomainFrequencyCaps.Builder
    if (!isJSONObjectType(caps)) {
        throw new HydrationException(caps, DomainFrequencyCaps)
    }

    if (caps.prompt) {
        builder.setPrompt(hydrateDomainFrequencyCap(caps.prompt))
    }
    if (caps.notification) {
        builder.setNotification(hydrateDomainFrequencyCap(caps.notification))
    }

    return builder.build()
}

export function hydrateDomainFrequencyCap(
    cap: DomainFrequencyCap | DomainFrequencyCapBuilder | IDomainFrequencyCap | any,
) {
    const builder = DomainFrequencyCap.Builder
    if (cap instanceof DomainFrequencyCap || cap instanceof DomainFrequencyCapBuilder) {
        builder.setOccurrences(cap.occurrences).setFcapSeconds(cap.fcapSeconds).setDisplayMetric(cap.displayMetric)
    } else {
        if (!isJSONObjectType(cap)) {
            throw new HydrationException(cap, DomainFrequencyCap)
        }
        builder
            .setOccurrences(parseInt(cap.occurrences, 10))
            .setFcapSeconds(parseInt(cap.fcapSeconds, 10))
            .setDisplayMetric(cap.displayMetric)
    }

    return builder.build()
}

// TODO: Either update model to allow usePrimaryLandingUrl to be undefined or write migration to fix data
export function hydrateNotificationActionModelFromDto(action: NotificationAction | NotificationActionBuilder | any) {
    let builder = NotificationAction.Builder
    if (action instanceof NotificationAction || action instanceof NotificationActionBuilder) {
        builder
            .setId(action.id)
            .setType(action.type)
            .setLabel(action.label)
            .setOrdinal(action.ordinal)
            .setLandingUrl(action.landingUrl)
            .setDisplayMeta(action.displayMeta)
            .setUsePrimaryLandingUrl(action.type === NotificationActionType.CLOSE ? undefined : !action.landingUrl)
    } else {
        if (!isJSONObjectType(action)) {
            throw new HydrationException(action, NotificationAction)
        }
        builder
            .setId(action.id)
            .setType(action.type)
            .setLabel(action.label)
            .setOrdinal(action.ordinal)
            .setLandingUrl(action.landingUrl)
            .setUsePrimaryLandingUrl(action.type === NotificationActionType.CLOSE ? undefined : !action.landingUrl)
            .setDisplayMeta(action.displayMeta)
    }

    return builder.build()
}

const hydrateUtmParams = (params: DomainUtmParams | DomainUtmParamsBuilder | any): DomainUtmParams | null => {
    if (params === null) return null

    let builder = DomainUtmParams.Builder
    if (params instanceof DomainUtmParams || params instanceof DomainUtmParamsBuilder) {
        builder
            .setUtmSource(params.utmSource)
            .setUtmContent(params.utmContent)
            .setUtmMedium(params.utmMedium)
            .setUtmCampaign(params.utmCampaign)
            .setUtmTerm(params.utmTerm)
    } else {
        if (!isJSONObjectType(params)) {
            throw new HydrationException(params, DomainUtmParams)
        }

        builder.parseJsonObject(params)
    }

    return builder.build()
}

// Send Integration Configuration Hydrators

export function hydrateWebApnsConfigurationModel(
    config: WebApnsSendIntegrationConfig | WebApnsSendIntegrationConfigBuilder | IWebApnsSendIntegration | any,
): WebApnsSendIntegrationConfig {
    const builder = WebApnsSendIntegrationConfig.Builder
    if (config instanceof WebApnsSendIntegrationConfig) {
        if (config.isActive) {
            builder
                .setIsActive(config.isActive)
                // when isActive is TRUE - these will have been validated by build() method
                .setPrivateKey(config.privateKey!)
                .setPushPackage(config.pushPackage!)
                .setExpiration(config.expiration)
        } else {
            builder.setIsActive(false)
        }
    } else if (config instanceof WebApnsSendIntegrationConfigBuilder) {
        if (config.isActive) {
            builder
                .setIsActive(config.isActive)
                .setPrivateKey(config.privateKey)
                .setPushPackage(config.pushPackage)
                .setExpiration(config.expiration)
        } else {
            builder.setIsActive(false)
        }
    } else {
        if (!isJSONObjectType(config)) {
            throw new Error()
        }

        if (config.isActive) {
            let privateKey = SendIntegrationPrivateKey.Builder
            if (config.privateKey) {
                if ('privateKeyFileUrl' in config.privateKey) {
                    privateKey.setPrivateKeyFileUrl(config.privateKey.privateKeyFileUrl)
                } else if ('private_key_file_url' in config.privateKey) {
                    privateKey.setPrivateKeyFileUrl(config.privateKey.private_key_file_url)
                }
                if ('privateKeyPassPhrase' in config.privateKey) {
                    privateKey.setPrivateKeyPassPhrase(config.privateKey.privateKeyPassPhrase)
                } else if ('private_key_pass_phrase' in config.privateKey) {
                    privateKey.setPrivateKeyPassPhrase(config.privateKey.private_key_pass_phrase)
                }
            }

            let pushPackage = SendIntegrationPushPackage.Builder
            if (config.pushPackage) {
                if ('websitePushId' in config.pushPackage) {
                    pushPackage.setWebsitePushId(config.pushPackage.websitePushId)
                } else if ('website_push_id' in config.pushPackage) {
                    pushPackage.setWebsitePushId(config.pushPackage.website_push_id)
                }

                if ('iconSourceUrl' in config.pushPackage) {
                    pushPackage.setIconSourceUrl(config.pushPackage.iconSourceUrl)
                } else if ('icon_source_url' in config.pushPackage) {
                    pushPackage.setIconSourceUrl(config.pushPackage.icon_source_url)
                }
            }

            builder
                .setIsActive(config.isActive)
                .setPrivateKey(privateKey.build())
                .setPushPackage(pushPackage.build())
                .setExpiration(config.expiration)
        } else {
            builder.setIsActive(false)
        }
    }

    return builder.build()
}

export function hydrateNativeApnsConfigurationModel(
    config:
        | NativeApnsSendIntegrationConfig
        | NativeApnsSendIntegrationConfigBuilder
        | NativeP12ApnsConfiguration
        | NativeP8ApnsConfiguration
        | IAbstractNativeApnsSendIntegration
        | any,
): NativeApnsSendIntegrationConfig {
    let model: NativeApnsSendIntegrationConfig
    if (config instanceof NativeApnsSendIntegrationConfig) {
        if (config.isActive) {
            if (config.privateKeyType === SendIntegrationPrivateKeyType.P8) {
                model = NativeApnsSendIntegrationConfig.buildActiveP8Configuration(config)
            } else {
                model = NativeApnsSendIntegrationConfig.buildActiveP12Configuration(config)
            }
        } else {
            model = NativeApnsSendIntegrationConfig.Builder.setIsActive(false).build()
        }
    } else if (config instanceof NativeApnsSendIntegrationConfigBuilder) {
        if (config.isActive) {
            if (config.privateKeyType === SendIntegrationPrivateKeyType.P8) {
                model = NativeApnsSendIntegrationConfig.buildActiveP8Configuration(config)
            } else {
                model = NativeApnsSendIntegrationConfig.buildActiveP12Configuration(config)
            }
        } else {
            model = NativeApnsSendIntegrationConfig.Builder.setIsActive(false).build()
        }
    } else if (config instanceof NativeP12ApnsConfiguration || config instanceof NativeP8ApnsConfiguration) {
        if (config.getIsActive()) {
            // type won't be inferred from privateKeyType === SendIntegrationKeyType because these are extended
            // from AbstractNativeApnsSendIntegration class that allows both...
            if (
                config.getPrivateKeyType() === SendIntegrationPrivateKeyType.P8 ||
                config instanceof NativeP8ApnsConfiguration
            ) {
                model = NativeApnsSendIntegrationConfig.buildActiveP8Configuration({
                    teamId: (config as NativeP8ApnsConfiguration).getTeamId(),
                    keyId: (config as NativeP8ApnsConfiguration).getKeyId(),
                    appBundleId: config.getAppBundleId(),
                    privateKeyFileUrl: config.getPrivateKeyFileUrl(),
                    additionalPermissions: config.getAdditionalPermissions(),
                    apnsEnvironment: config.getApnsEnvironment(),
                })
            } else {
                model = NativeApnsSendIntegrationConfig.buildActiveP12Configuration({
                    appBundleId: config.getAppBundleId(),
                    privateKeyFileUrl: config.getPrivateKeyFileUrl(),
                    privateKeyPassPhrase: config.getPrivateKeyPassPhrase(),
                    expiration: config.getExpiration(),
                    additionalPermissions: config.getAdditionalPermissions(),
                    apnsEnvironment: config.getApnsEnvironment(),
                })
            }
        } else {
            model = NativeApnsSendIntegrationConfig.Builder.setIsActive(false).build()
        }
    } else {
        if (!isJSONObjectType(config)) {
            throw new HydrationException(config, NativeApnsSendIntegrationConfig)
        }

        if (config.isActive) {
            if (config.privateKeyType === SendIntegrationPrivateKeyType.P8) {
                model = NativeApnsSendIntegrationConfig.buildActiveP8Configuration({
                    teamId: config.teamId,
                    keyId: config.keyId,
                    appBundleId: config.appBundleId,
                    privateKeyFileUrl: config.privateKeyFileUrl,
                    additionalPermissions: config.additionalPermissions,
                    apnsEnvironment: config.apnsEnvironment,
                })
            } else {
                model = NativeApnsSendIntegrationConfig.buildActiveP12Configuration({
                    appBundleId: config.appBundleId,
                    privateKeyFileUrl: config.privateKeyFileUrl,
                    privateKeyPassPhrase: config.privateKeyPassPhrase,
                    expiration: config.expiration,
                    additionalPermissions: config.additionalPermissions,
                    apnsEnvironment: config.apnsEnvironment,
                })
            }
        } else if (config.is_active) {
            if (config.private_key_type === SendIntegrationPrivateKeyType.P8) {
                model = NativeApnsSendIntegrationConfig.buildActiveP8Configuration({
                    teamId: config.team_id,
                    keyId: config.key_id,
                    appBundleId: config.app_bundle_id,
                    privateKeyFileUrl: config.private_key_file_url,
                    additionalPermissions: config.additional_permissions,
                    apnsEnvironment: config.apns_environment,
                })
            } else {
                model = NativeApnsSendIntegrationConfig.buildActiveP12Configuration({
                    appBundleId: config.app_bundle_id,
                    privateKeyFileUrl: config.private_key_file_url,
                    privateKeyPassPhrase: config.private_key_pass_phrase,
                    expiration: config.expiration,
                    additionalPermissions: config.additional_permissions,
                    apnsEnvironment: config.apns_environment,
                })
            }
        } else {
            model = NativeApnsSendIntegrationConfig.Builder.setIsActive(false).build()
        }
    }

    return model
}

export function hydrateNativeFcmConfigurationModel(
    config:
        | NativeFcmSendIntegrationConfig
        | NativeFcmSendIntegrationConfigBuilder
        | NativeV1FcmConfiguration
        | NativeLegacyFcmConfiguration
        | IAbstractNativeFcmSendIntegration
        | any,
): NativeFcmSendIntegrationConfig {
    let model: NativeFcmSendIntegrationConfig
    if (config instanceof NativeFcmSendIntegrationConfig) {
        if (config.isActive) {
            if (config.apiVersion === FcmSendIntegrationApiVersion.V1) {
                model = NativeFcmSendIntegrationConfig.buildActiveV1Configuration(config)
            } else {
                model = NativeFcmSendIntegrationConfig.buildActiveLegacyConfiguration(config)
            }
        } else {
            model = NativeFcmSendIntegrationConfig.Builder.setIsActive(false).build()
        }
    } else if (config instanceof NativeFcmSendIntegrationConfigBuilder) {
        if (config.isActive) {
            if (config.apiVersion === FcmSendIntegrationApiVersion.V1) {
                model = NativeFcmSendIntegrationConfig.buildActiveV1Configuration(config)
            } else {
                model = NativeFcmSendIntegrationConfig.buildActiveLegacyConfiguration(config)
            }
        } else {
            model = NativeFcmSendIntegrationConfig.Builder.setIsActive(false).build()
        }
    } else if (config instanceof NativeV1FcmConfiguration || config instanceof NativeLegacyFcmConfiguration) {
        if (config.getIsActive()) {
            if (config instanceof NativeV1FcmConfiguration) {
                model = NativeFcmSendIntegrationConfig.buildActiveV1Configuration({
                    senderId: config.getSenderId(),
                    serviceAccountJsonFileUrl: config.getServiceAccountJsonFileUrl(),
                })
            } else {
                model = NativeFcmSendIntegrationConfig.buildActiveLegacyConfiguration({
                    serverApiKey: config.getServerApiKey(),
                    senderId: config.getSenderId(),
                })
            }
        } else {
            model = NativeFcmSendIntegrationConfig.Builder.setIsActive(false).build()
        }
    } else {
        if (!isJSONObjectType(config)) {
            throw new HydrationException(config, NativeFcmSendIntegrationConfig)
        }
        if (config.isActive) {
            if (config.apiVersion === FcmSendIntegrationApiVersion.V1) {
                model = NativeFcmSendIntegrationConfig.buildActiveV1Configuration({
                    senderId: config.senderId,
                    serviceAccountJsonFileUrl: config.serviceAccountJsonFileUrl,
                })
            } else {
                model = NativeFcmSendIntegrationConfig.buildActiveLegacyConfiguration({
                    serverApiKey: config.serverApiKey,
                    senderId: config.senderId,
                })
            }
        } else if (config.is_active) {
            if (config.api_version === FcmSendIntegrationApiVersion.V1) {
                model = NativeFcmSendIntegrationConfig.buildActiveV1Configuration({
                    senderId: config.sender_id,
                    serviceAccountJsonFileUrl: config.service_account_json_file_url,
                })
            } else {
                model = NativeFcmSendIntegrationConfig.buildActiveLegacyConfiguration({
                    serverApiKey: config.server_api_key,
                    senderId: config.sender_id,
                })
            }
        } else {
            model = NativeFcmSendIntegrationConfig.Builder.setIsActive(false).build()
        }
    }
    return model
}

export function hydrateNotifNamedTemplate(config: NotificationNamedTemplate | NotificationNamedTemplateBuilder | any) {
    if (!isJSONObjectType(config)) {
        throw new HydrationException(config, NotificationNamedTemplate)
    }
    let builder = NotificationNamedTemplate.Builder
    if (config instanceof NotificationNamedTemplate || config instanceof NotificationNamedTemplateBuilder) {
        builder
            .setId(config.id)
            .setDomainId(config.domainId)
            .setName(config.name)
            .setChannels(config.channels.map((ch) => DeliveryChannel[ch]))
            .setDescription(config.description)
            .setKeywords(config.keywords)
            .setSourceTemplateId(config.sourceTemplateId)
            .setTemplateSegmentIds(config.templateSegmentIds)
            .setTemplateExcludedSegmentIds(config.templateExcludedSegmentIds)
            .setTemplateTtlSpec(config.templateTtlSpec)
            .setTemplateContent(config.templateContent)
            .setCreatedAt(config.createdAt ?? null)
            .setCreatedBy(config.createdBy ?? null)
    } else {
        builder
            .setId(config.id)
            .setDomainId(config.domainId)
            .setName(config.name)
            .setChannels(config.channels ?? [])
            .setDescription(config.description)
            .setKeywords(config.keywords ?? [])
            .setSourceTemplateId(config.sourceTemplateId)
            .setTemplateSegmentIds(config.templateSegmentIds)
            .setTemplateExcludedSegmentIds(config.templateExcludedSegmentIds)
            .setTemplateTtlSpec(config.templateTtlSpec)
            .setTemplateContent(hydrateTemplateContent(config.templateContent))
            .setCreatedAt(config.createdAt)
            .setCreatedBy(config.createdBy)
    }

    return builder.build()
}

export function hydrateTemplateContent(config: NotificationContentTemplate | NotificationContentTemplateBuilder | any) {
    if (!isJSONObjectType(config)) {
        throw new HydrationException(config, NotificationContentTemplate)
    }

    const contentTemplate = NotificationContentTemplate.Builder
    if ('default' in config) {
        contentTemplate.setDefault(hydrateDefaultContent(config.default))
    }

    if ('web' in config) {
        contentTemplate.setWeb(hydrateBaseContent(config.web))
    }

    if ('nativeIos' in config) {
        contentTemplate.setNativeIos(hydrateNativeIOSContent(config.nativeIos))
    }

    if ('nativeAndroid' in config) {
        contentTemplate.setNativeAndroid(hydrateBaseContent(config.nativeAndroid))
    }

    return contentTemplate.build()
}

export function hydrateDefaultContent(config: NotificationDefaultContent | NotificationDefaultContentBuilder | any) {
    if (!isJSONObjectType(config)) {
        throw new HydrationException(config, NotificationDefaultContent)
    }

    if (config instanceof NotificationDefaultContent) {
        return config
    } else if (config instanceof NotificationDefaultContentBuilder) {
        return config.build()
    } else {
        return NotificationDefaultContent.Builder.setTitle(config.title)
            .setActions(config.actions?.map((action) => hydrateNotificationActionModelFromDto(action)) ?? null)
            .setBody(config.body)
            .setLandingUrl(config.landingUrl)
            .setIconUrl(config.iconUrl)
            .setImageUrl(config.imageUrl)
            .setBadgeUrl(config.badgeUrl)
            .setChannels(config.channels ?? [])
            .setIsUsingDomainDefaultIcon(config.isUsingDomainDefaultIcon)
            .setIsUsingDomainDefaultBadge(config.isUsingDomainDefaultBadge)
            .setKeywords(config.keywords)
            .setIsSilent(config.isSilent)
            .setRequireInteraction(config.requireInteraction)
            .setOverrideDefaultActions(config.overrideDefaultActions)
            .setContentWebhookUrl(config.contentWebhookUrl)
            .setOverrideFallbackTemplate(config.overrideFallbackTemplate)
            .setAutoSuggestedFieldsResults(config.autoSuggestedFieldsResults)
            .setIsUserTemplate(config.isUserTemplate)
            .build()
    }
}

export function hydrateNativeIOSContent(config: NotificationIOSContent | NotificationIOSContentBuilder | any) {
    if (!isJSONObjectType(config)) {
        throw new HydrationException(config, NotificationIOSContent)
    }

    if (config instanceof NotificationIOSContent) {
        return config
    } else if (config instanceof NotificationIOSContentBuilder) {
        return config.build()
    } else {
        return NotificationIOSContent.Builder.setTitle(config.title)
            .setBody(config.body)
            .setLandingUrl(config.landingUrl)
            .setIconUrl(config.iconUrl)
            .setImageUrl(config.imageUrl)
            .setBadgeUrl(config.badgeUrl)
            .setBadgeBehavior(config.badgeBehavior)
            .setBadgeCount(config.badgeCount)
            .setSound(config.sound)
            .setSubtitle(config.subtitle)
            .setTargetContentId(config.targetContentId)
            .setInterruptionLevel(config.interruptionLevel)
            .setRelevanceScore(config.relevanceScore)
            .build()
    }
}

export function hydrateBaseContent(config: NotificationContentBase | any) {
    if (!isJSONObjectType(config)) {
        throw new HydrationException(config, NotificationContentBase)
    }

    if (config instanceof NotificationContentBase) {
        return config
    }

    return NotificationContentBase.from(config)
}
