import React from 'react'
import { useDataViewReducer } from '@pushly/aqe/lib/components/data-view/reducer'
import { DataView } from '@pushly/aqe/lib/components/data-view/data-view'
import { DataViewContext } from '@pushly/aqe/lib/components/data-view/context'
import { DataSourceResponse, DataViewProps } from '@pushly/aqe/lib/components/data-view/types'
import { Button, Radio, Table, Tooltip } from 'antd'
import { useService } from '@pushly/aqe/lib/hooks'
import { CheckCircleFilled, InfoCircleOutlined, ReadOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import './feeds-data-view.scss'
import { convertToStringDay } from '../../_utils/utils'
import { FeedService } from '../../services/feed.service'
import { stripUndefined } from '@pushly/aquarium-utils/lib/common/helpers/object'
import { useStickyValue } from '@pushly/aqe/lib/hooks/use-sticky-value'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { WebBadge } from '../badges/web-badge'
import { NativeIosBadge } from '../badges/native-ios-badge'
import { NativeAndroidBadge } from '../badges/native-android-badge'
import { FeedSendStrategy, RelativeDateDisplayMetric } from '@pushly/aqe/lib/enums/index'
import { getClassNames, secondsToHours, secondsToMinutes } from '@pushly/aqe/lib/utils'
import { DomainDto } from '../../dtos/domain'
import { Feed } from '@pushly/models/lib/structs/feeds/feed'
import { FeedResponse } from '@pushly/models/lib/structs/feeds/feed-response'
import { AppService } from '../../services/index'
import titleCase from 'title-case'
import deepEqual from 'react-fast-compare'
import { FeedAudienceBadge } from '@pushly/aqe/lib/components/feed-audience-badge/feed-audience-badge'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { NoTranslate } from '../no-translate/no-translate'
import { Icon as LegacyIcon } from '@ant-design/compatible'

enum FeedStatus {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
}

const STATUS_OPTIONS = [
    { value: FeedStatus.ENABLED, label: titleCase(FeedStatus.ENABLED) },
    { value: FeedStatus.DISABLED, label: titleCase(FeedStatus.DISABLED) },
]

export const FeedsDataView = (props: { domain: DomainDto }) => {
    const feedSvc = useService(FeedService)
    const appSvc = useService(AppService)

    const [context, dispatch, requestDataSourceReload] = useDataViewReducer<FeedResponse>({
        id: `feeds`,
        levelResource: {
            level: 'domain',
            id: props.domain.id,
        },
        defaultPageSize: 20,
    })

    const defaultStatusFilterValue = [FeedStatus.ENABLED, FeedStatus.DISABLED]
    const [getStatusFilter, setStatusFilter] = useStickyValue(
        localStorage,
        `sw.feeds`,
        `d.${props.domain.id}.shared.f.status`,
        defaultStatusFilterValue,
    )
    const statusFilter = getStatusFilter()

    const fetchFeeds: DataViewProps<Feed>['dataSourceLoader'] = async (filters, tableState) => {
        if (filters.status && !deepEqual(filters.status, statusFilter)) {
            setStatusFilter(filters.status)
        }

        const apiOptions = stripUndefined({
            query: {
                search: filters.search ?? undefined,
                page: tableState.pagination.current,
                limit: tableState.pagination.pageSize,
                status: filters.status?.length
                    ? filters.status.join(',')
                    : statusFilter.length
                    ? statusFilter.join(',')
                    : undefined,
            },
        })

        const res = await feedSvc.fetchDomainFeeds(props.domain.id, apiOptions)
        let dataViewResponse: DataSourceResponse = {
            meta: {},
            cancelled: false,
            ...res,
            data: [],
        }

        if (!res.cancelled) {
            if (res.ok && res.data) {
                dataViewResponse.data = res.data
            } else if (res.error) {
                console.warn(res.error)
            }
        }

        return dataViewResponse
    }

    const renderActions = React.useCallback(
        (feed: FeedResponse) => {
            // TODO: add actions when we enable more feeds functionality on the public side
            const actions: any[] = []

            return (
                // <Dropdown.Button
                //     size="small"
                //     className="feeds-row-actions-btn"
                //     onClick={() => handlePrimaryClick(feed)}
                //     overlay={
                //         actions.length === 0 ? <></> : (
                //             <Menu onClick={({ key, domEvent }) => {}}>
                //                 {actions.map((item) => (
                //                     <Menu.Item key={`${item.key}`}>
                //                         {item.icon}
                //                         <span>{item.text}</span>
                //                     </Menu.Item>
                //                 ))}
                //             </Menu>
                //         )
                //     }
                // >
                //     <span>View</span>
                // </Dropdown.Button>
                <Button size="small" onClick={() => handlePrimaryClick(feed)}>
                    <span>View</span>
                </Button>
            )
        },
        [props.domain.id],
    )

    const renderFeedDisplay = (data: FeedResponse) => {
        return (
            <TableRowEntityDisplay
                title={<NoTranslate>{data.name}</NoTranslate>}
                status={
                    <span className="status-badge feed-status-badge">
                        <span className={`status-icon status-${data.enabled ? 'ENABLED' : 'DISABLED'}`}>
                            <LegacyIcon type={data.enabled ? 'check-circle' : 'warning'} />
                        </span>
                        <span className="status-name">{titleCase(data.enabled ? 'ENABLED' : 'DISABLED')}</span>
                    </span>
                }
                audience={
                    <span className="audience-type">
                        <FeedAudienceBadge source={data} appService={appSvc} hideTooltip={false} />
                    </span>
                }
                badges={
                    <>
                        {data?.config?.channels?.includes(DeliveryChannel.WEB) && <WebBadge />}
                        {data?.config?.channels?.includes(DeliveryChannel.NATIVE_IOS) && <NativeIosBadge />}
                        {data?.config?.channels?.includes(DeliveryChannel.NATIVE_ANDROID) && <NativeAndroidBadge />}
                    </>
                }
            />
        )
    }

    const handlePrimaryClick = React.useCallback(
        (feed: FeedResponse) => {
            const url = `/domains/${props.domain.id}/notifications/feeds/${feed.id}`
            return appSvc.route(url, { feed, domain: props.domain })
        },
        [props.domain.id],
    )

    return (
        <div className={getClassNames('feeds-data-view')}>
            <DataViewContext.Provider value={{ state: context, dispatch }}>
                <DataView
                    dataSourceLoader={fetchFeeds}
                    hideRefresh={true}
                    hideAutoRefreshOptions={true}
                    filters={[
                        {
                            key: 'search',
                            type: 'search',
                            componentProps: {
                                className: getClassNames('feed-list-search'),
                                placeholder: 'Search Feed ID or Name',
                            },
                        },
                        {
                            key: 'status',
                            type: 'select',
                            componentProps: {
                                className: 'status-filter-select',
                                prefix: 'Status',
                                placeholder: 'Select Statuses',
                                options: STATUS_OPTIONS,
                                value: statusFilter,
                                disableSearch: true,
                                disableSelectAll: true,
                                closeOnEscape: true,
                                maxDisplayCount: 2,
                                maxDisplayFormatter: (options) => {
                                    const multi = options.length > 1
                                    return (
                                        <Tooltip title={options.map((o) => o.label).join(', ')}>
                                            {options.length} {multi ? 'Statuses' : 'Status'}
                                        </Tooltip>
                                    )
                                },
                            },
                        },
                    ]}
                    table={{
                        rowKey: (row) => row.id,
                        noData: {
                            text: 'No Feeds',
                            icon: <ReadOutlined />,
                        },
                        rowClassName: 'data-view-row-collapsed',
                    }}
                >
                    <Table.Column
                        key="id"
                        className="feed"
                        dataIndex=""
                        title="Feed"
                        width="40%"
                        render={renderFeedDisplay}
                    />
                    <Table.Column
                        align="center"
                        className="delivery-strategy"
                        dataIndex=""
                        key="delivery-strategy"
                        title={
                            <div className="delivery-strategy-label">
                                Delivery Strategy
                                <Tooltip
                                    overlayClassName="wide-tooltip"
                                    title={
                                        <React.Fragment>
                                            <p>
                                                The delivery strategy determines when and how often a notification will
                                                be created for a feed:
                                            </p>

                                            <p>
                                                &#8226; ASAP: A notification will be created every time a new article is
                                                discovered and a previous notification has not been sent based on the
                                                specified send frequency.
                                            </p>

                                            <p>
                                                &#8226; Fixed: A notification will be scheduled once a day at the
                                                specified send time in the domain’s time zone. If a new article is not
                                                found at the specified time we will continue checking for a maximum of 1
                                                hour after the send time.
                                            </p>

                                            <p>
                                                &#8226; Subscriber Time Zone: A notification will be scheduled at the
                                                specified send time in each subscriber’s time zone. In order to account
                                                for all time zones the article will be chosen at least 24 hours in
                                                advance of the send time.
                                            </p>
                                        </React.Fragment>
                                    }
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </div>
                        }
                        render={(data) => {
                            const schedule = data.config?.schedule
                            return (
                                <div className="delivery-strategy-data-view">
                                    <div className="delivery-strategy-display">
                                        <span>{`${
                                            (schedule?.sendStrategy === FeedSendStrategy.FIXED && 'Fixed') ||
                                            (schedule?.sendStrategy === FeedSendStrategy.ASAP && 'ASAP') ||
                                            (schedule?.sendStrategy === FeedSendStrategy.SUBSCRIBER_TIME_ZONE &&
                                                'STZ') ||
                                            ''
                                        }`}</span>
                                    </div>
                                </div>
                            )
                        }}
                    />
                    <Table.Column
                        align="left"
                        className="send-time"
                        dataIndex=""
                        key="send-time"
                        title="Send Time"
                        render={(data) => {
                            const schedule = data.config?.schedule
                            return schedule?.startTime ? (
                                <div className="send-time-data-view">
                                    <div className="send-time-display">
                                        <span>
                                            {(schedule?.sendStrategy === FeedSendStrategy.ASAP &&
                                                `Every ${secondsToMinutes(
                                                    schedule?.minFrequency?.intervalSeconds,
                                                )} Minutes on `) ||
                                                (schedule?.sendStrategy === FeedSendStrategy.FIXED &&
                                                    `${moment(schedule?.startTime, 'HH:mm')?.format(
                                                        'h:mm A',
                                                    )} ${moment()
                                                        .tz(props.domain?.timezone)
                                                        ?.format('z')} on `) ||
                                                (schedule?.sendStrategy === FeedSendStrategy.SUBSCRIBER_TIME_ZONE &&
                                                    `${moment(schedule?.startTime, 'HH:mm')?.format(
                                                        'h:mm A',
                                                    )} STZ on `)}
                                        </span>
                                        <span className="send-time-text">{`${convertToStringDay(
                                            schedule.dayOfWeekIso,
                                        )}`}</span>
                                        <span>
                                            {schedule?.sendStrategy === FeedSendStrategy.ASAP &&
                                                ` (${moment(schedule?.startTime, 'HH:mm').format('h:mm A')} - ${moment(
                                                    schedule?.endTime,
                                                    'HH:mm',
                                                ).format('h:mm A')})`}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <span>New Article Posted</span>
                            )
                        }}
                    />
                    <Table.Column
                        align="left"
                        className="delivery-delay"
                        colSpan={1}
                        dataIndex=""
                        key="delivery-delay"
                        title="Pre-Schedule"
                        render={(data) => {
                            const deliveryDelay = data.config?.schedule?.deliveryDelay
                            return deliveryDelay ? (
                                <span className="contained">
                                    {deliveryDelay.displayMetric === RelativeDateDisplayMetric.MINUTES
                                        ? `${secondsToMinutes(deliveryDelay.intervalSeconds)} ${
                                              deliveryDelay.displayMetric
                                          }`
                                        : `${secondsToHours(deliveryDelay.intervalSeconds)} ${
                                              deliveryDelay.displayMetric
                                          }`}
                                </span>
                            ) : (
                                <span>--</span>
                            )
                        }}
                    />
                    <Table.Column
                        align="right"
                        className="actions"
                        dataIndex=""
                        key="actions"
                        title="Actions"
                        render={(data) => renderActions(data)}
                    />
                </DataView>
            </DataViewContext.Provider>
        </div>
    )
}
