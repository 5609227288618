import * as React from 'react'
import { Container } from 'typescript-ioc'
import { useLoadableDataState } from '@pushly/aqe/lib/hooks/index'
import './feed-page.scss'
import { FeedPageHeader } from './feed-page-header'
import { useState } from 'react'
import { DomainService } from '../../services/index'
import { DomainDto } from '../../dtos/domain'
import { Feed } from '@pushly/models/lib/structs/feeds/feed'
import { FeedService } from '../../services/feed.service'
import { getClassNames } from '@pushly/aqe/lib/utils'
import { FeedDetailsWell } from './feed-details-well'

interface ILoadableDataState<T> {
    data?: {
        feed: T
        meta: any
    }
    loading: boolean
}
export type LoadableDataState<T> = ILoadableDataState<T>

export const FeedPage = (props: any) => {
    const domainSvc: DomainService = Container.get(DomainService)
    const feedSvc: FeedService = Container.get(FeedService)

    const domainId = parseInt(props.match.params.domainId, 10)
    const feedId = props.match.params.feedId

    // ----------------------------------  Stateful Variables  ---------------------------------- //
    const [domain, setDomain] = useState<DomainDto>()
    const [feed, setFeed] = useLoadableDataState<{ feed: Feed | any; meta: any }>({ data: { feed: {}, meta: {} } })

    // ---------------------------------- Rendering Functions ---------------------------------- //
    async function fetchDomain() {
        const { data } = await domainSvc.fetchById(domainId)

        setDomain(data)
    }
    async function fetchFeed() {
        setFeed((state) => ({ ...state, loading: true }))
        const { data: feedResponse, meta } = await feedSvc.fetchFeedById(domainId, feedId)

        setFeed({ loading: false, data: { feed: feedResponse, meta } })
    }

    React.useEffect(() => {
        fetchDomain()
        fetchFeed()
    }, [feedId, domainId])

    function render() {
        return (
            <div className={getClassNames('feed-details-page')}>
                <FeedPageHeader domainName={domain?.displayName} feed={feed} />

                {feed.data?.feed.id && <FeedDetailsWell feed={feed} domainId={domainId} />}
            </div>
        )
    }

    return render()
}
