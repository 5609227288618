import type { PushlySdk } from '../types/pushly-sdk'
import { injectExternalScript } from '../hooks/use-external-script'
import aqe from '@pushly/aqe'

export const injectPushlySdk = (domainKey: string) => {
    console.debug(`Initializing sdk for ${domainKey}`)

    window.PlatformNotifications = {
        supported: true,
    }

    window.PushlySDK = window.PushlySDK || []
    window.pushly = function (this: any) {
        window.PushlySDK!.push(arguments)
    }

    injectExternalScript({
        src: `${aqe.defaults.publicCdnDomain}/pushly-sdk.min.js?domain_key=${domainKey}`,
        async: true,
    })

    window.pushly('load', { domainKey })

    window.pushly('on_web_push_unsupported', () => {
        window.PlatformNotifications!.supported = false
    })
}

export const getPushlySdk = (): PushlySdk | undefined => {
    return Array.isArray(window.PushlySDK) ? undefined : window.PushlySDK
}
