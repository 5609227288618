import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { BetterComponent } from '../../components/better-component/better-component'
import { ModuleLoadingScreen } from '../../components/module-loading-screen/module-loading-screen'
import { Route, Switch } from '../../components/router'
import { GetDomainCode } from './get-domain-code/get-domain-code'
import { PromptEditor } from '../prompts'
import { NotificationDetails } from '../notifications'
import { Dashboard } from '../dashboard'
import Sandbox from '../sandbox/sandbox'
import { TestDetails } from '../notifications/notification-details/notification-test-details'
import { Redirect } from 'react-router'
import { CampaignList } from '../campaigns/campaign-list/campaign-list'
import { CreateCampaign } from '../campaigns/create-campaign/create-campaign'
import { CampaignDetails } from '../campaigns/campaign-details/campaign-details'
import { EditCampaign } from '../campaigns/edit-campaign/edit-campaign'
import { Domain } from '../post-auth/domain'
import { snakeCase } from 'change-case'
import { SendNotification2 } from '../notifications/send-notification/send-notification-2'
import { EditNotification2 } from '../notifications/edit-notification/edit-notification-2'
import Notifications from '../post-auth/notifications'
import { Insights } from '../reporting_v2/insights'
import Segments from '../post-auth/segments'
import Segment from '../post-auth/segment'
import { SegmentDetails } from '../segments/segment-details/segment-details'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { ICaslSubject } from '../../stores/app-ability'
import { DomainDto } from '../../dtos/domain'
import { AppState } from '../../stores/app'
import { observer } from 'mobx-react'
import { ErrorPage } from '../../components/error-page/error-page'
import { buildComponentCheck } from '../../_utils/routing'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { Prompts } from '../prompts/prompts'
import { BuildNamedNotificationTemplate } from '../notifications/named-notification-template/build-named-notification-template'
import { EditNamedNotificationTemplate } from '../notifications/named-notification-template/edit-named-notification-template'
import AppMessages from '../post-auth/app-messages'
import { BuildAppMessage } from '../app-messages/build-app-message/build-app-message'
import { getEnabledDeliveryChannels } from '../../_utils/domain'
import { AppService } from '../../services'
import { AppMessageDetailsView } from '../app-messages/app-message-details/app-message-details-view'
import { FEAT_APP_MESSAGES } from '../../constants'
import { PlatformUserDomainRecord } from '../../models/domain/platform-user-domain-record'
import { FeedPage } from '../../components/feeds/feed-page'

interface IProps {}

interface IState {}

@observer
export class DomainFeature extends BetterComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        // Optional chain is used here to account for a rare
        // race condition where ACS has not been initialized
        // due to network activity.
        //
        // TODO: Investigate better state observation to trigger
        //  the a rerender without the need to extract acs.state
        const _ = this.appState.abilityStore.acs?.state

        const pathClassname = snakeCase(this.injectedProps.location?.pathname ?? '').replace(/_?[\d]+_?/g, '_')

        return (
            <div className={`module notifications ${pathClassname}`}>
                <ModuleLoadingScreen />

                <Switch>
                    <Route path={`${this.matchedPath}/__sandbox__`} exact={true} component={Sandbox} />
                    <Route
                        path={`${this.matchedPath}/dashboard`}
                        exact={true}
                        component={buildComponentCheck(Dashboard, [
                            [() => this.accessCheck(AbilityAction.READ, this.appState.currentDomain!)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/insights`}
                        exact={true}
                        component={buildComponentCheck(Insights, [
                            [() => this.accessCheck(AbilityAction.READ, this.appState.currentDomain!)],
                        ])}
                    />

                    <Route
                        path={`${this.matchedPath}`}
                        exact={true}
                        component={buildComponentCheck(Domain, [
                            [() => this.accessCheck(AbilityAction.UPDATE, this.appState.currentDomain!)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/code`}
                        exact={true}
                        component={buildComponentCheck(GetDomainCode, [
                            [() => this.accessCheck(AbilityAction.READ, this.appState.currentDomain!)],
                        ])}
                    />

                    <Route
                        path={[
                            `${this.matchedPath}/notifications`,
                            `${this.matchedPath}/notifications/drafts`,
                            `${this.matchedPath}/notifications/templates`,
                            `${this.matchedPath}/notifications/feeds`,
                        ]}
                        exact={true}
                        component={buildComponentCheck(Notifications, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.NOTIFICATION)],
                        ])}
                    />
                    <Redirect
                        from={`${this.matchedPath}/notifications/:notificationId([0-9]+)`}
                        to={`${this.matchedPath}/notifications/:notificationId([0-9]+)/summary`}
                        exact={true}
                    />
                    <Route
                        path={`${this.matchedPath}/notifications/:notificationId([0-9]+)/:tab([\\w]+)`}
                        exact={true}
                        component={buildComponentCheck(NotificationDetails, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.NOTIFICATION)],
                        ])}
                    />
                    <Redirect
                        from={`${this.matchedPath}/notifications/test/:testId([0-9]+)`}
                        to={`${this.matchedPath}/notifications/test/:testId([0-9]+)/summary`}
                        exact={true}
                    />
                    <Route
                        path={`${this.matchedPath}/notifications/test/:testId([0-9]+)/:tab([\\w]+)`}
                        exact={true}
                        component={buildComponentCheck(TestDetails, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.NOTIFICATION_TEST)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/notifications/new`}
                        exact={true}
                        component={buildComponentCheck(SendNotification2, [
                            [() => this.accessCheck(AbilityAction.CREATE, SubjectEntity.NOTIFICATION)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/notifications/edit/:notificationId([0-9]+)`}
                        exact={true}
                        component={buildComponentCheck(EditNotification2, [
                            [() => this.accessCheck(AbilityAction.UPDATE, SubjectEntity.NOTIFICATION)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/notifications/templates/new`}
                        exact={true}
                        component={buildComponentCheck(BuildNamedNotificationTemplate, [
                            [() => this.accessCheck(AbilityAction.CREATE, SubjectEntity.NOTIFICATION)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/notifications/templates/edit/:templateId([0-9]+)`}
                        exact={true}
                        component={buildComponentCheck(EditNamedNotificationTemplate, [
                            [() => this.accessCheck(AbilityAction.UPDATE, SubjectEntity.NOTIFICATION)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/notifications/feeds/:feedId([0-9]+)`}
                        exact={true}
                        component={buildComponentCheck(FeedPage, [
                            [() => this.accessCheck(AbilityAction.UPDATE, SubjectEntity.NOTIFICATION)],
                        ])}
                    />

                    <Route
                        path={[`${this.matchedPath}/app-messages`, `${this.matchedPath}/app-messages/templates`]}
                        exact={true}
                        component={buildComponentCheck(AppMessages, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.APP_MESSAGE)],
                            [
                                () => this.flagsCheck(this.appState.currentDomain!.flags),
                                this.appMessagesNotImplementedComponent,
                            ],
                            [
                                () => this.channelCheck([DeliveryChannel.NATIVE_IOS, DeliveryChannel.NATIVE_ANDROID]),
                                this.nativeNotImplementedComponent,
                            ],
                        ])}
                    />

                    <Route
                        path={[
                            `${this.matchedPath}/app-messages/new`,
                            `${this.matchedPath}/app-messages/templates/new`,
                            `${this.matchedPath}/app-messages/:appMessageId([0-9]+)/edit`,
                            `${this.matchedPath}/app-messages/templates/:appMessageTemplateId([0-9]+)/edit`,
                        ]}
                        exact={true}
                        component={buildComponentCheck(BuildAppMessage, [
                            [() => this.accessCheck(AbilityAction.CREATE, SubjectEntity.APP_MESSAGE)],
                            [
                                () => this.flagsCheck(this.appState.currentDomain!.flags),
                                this.appMessagesNotImplementedComponent,
                            ],
                            [
                                () =>
                                    this.channelCheck(
                                        [DeliveryChannel.NATIVE_IOS, DeliveryChannel.NATIVE_ANDROID],
                                        true,
                                    ),
                                this.appMessageNativeRequiredComponent,
                            ],
                        ])}
                    />

                    <Redirect
                        from={`${this.matchedPath}/app-messages/:appMessageId([0-9]+)`}
                        to={`${this.matchedPath}/app-messages/:appMessageId([0-9]+)/summary`}
                        exact={true}
                    />

                    <Route
                        path={`${this.matchedPath}/app-messages/:appMessageId([0-9]+)/:tab([\\w]+)`}
                        exact={true}
                        component={buildComponentCheck(AppMessageDetailsView, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.APP_MESSAGE)],
                            [
                                () => this.flagsCheck(this.appState.currentDomain!.flags),
                                this.appMessagesNotImplementedComponent,
                            ],
                            [
                                () => this.channelCheck([DeliveryChannel.NATIVE_IOS, DeliveryChannel.NATIVE_ANDROID]),
                                this.nativeNotImplementedComponent,
                            ],
                        ])}
                    />

                    <Route
                        path={`${this.matchedPath}/campaigns`}
                        exact={true}
                        component={buildComponentCheck(CampaignList, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.CAMPAIGN)],
                        ])}
                    />

                    <Route
                        path={`${this.matchedPath}/campaigns/new`}
                        exact={true}
                        component={buildComponentCheck(CreateCampaign, [
                            [() => this.accessCheck(AbilityAction.CREATE, SubjectEntity.CAMPAIGN)],
                        ])}
                    />

                    <Route
                        path={`${this.matchedPath}/campaigns/:campaignId([0-9]+)/:tab(configuration|builder)`}
                        exact={true}
                        component={buildComponentCheck(EditCampaign, [
                            [() => this.accessCheck(AbilityAction.UPDATE, SubjectEntity.CAMPAIGN)],
                        ])}
                    />

                    <Redirect
                        from={`${this.matchedPath}/campaigns/:campaignId([0-9]+)`}
                        to={`${this.matchedPath}/campaigns/:campaignId([0-9]+)/summary`}
                        exact={true}
                    />
                    <Route
                        path={`${this.matchedPath}/campaigns/:campaignId([0-9]+)/:tab([\\w]+)`}
                        exact={true}
                        component={buildComponentCheck(CampaignDetails, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.CAMPAIGN)],
                        ])}
                    />

                    <Route
                        path={[
                            `${this.matchedPath}/prompts`,
                            `${this.matchedPath}/prompts/native_ios`,
                            `${this.matchedPath}/prompts/native_android`,
                        ]}
                        exact={true}
                        component={buildComponentCheck(Prompts, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.PROMPT)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/prompts/new`}
                        exact={true}
                        component={buildComponentCheck(PromptEditor, [
                            [() => this.accessCheck(AbilityAction.CREATE, SubjectEntity.PROMPT)],
                            [() => this.channelCheck([DeliveryChannel.WEB]), this.webNotImplementedComponent],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/prompts/:promptId([0-9]+)`}
                        exact={true}
                        component={buildComponentCheck(PromptEditor, [
                            [() => this.accessCheck(AbilityAction.UPDATE, SubjectEntity.PROMPT)],
                            [() => this.channelCheck([DeliveryChannel.WEB]), this.webNotImplementedComponent],
                        ])}
                    />

                    <Route
                        path={`${this.matchedPath}/segments/new`}
                        exact={true}
                        component={buildComponentCheck(Segment, [
                            [() => this.accessCheck(AbilityAction.CREATE, SubjectEntity.SEGMENT)],
                        ])}
                    />
                    <Route
                        path={[`${this.matchedPath}/segments`, `${this.matchedPath}/segments/compare`]}
                        exact={true}
                        component={buildComponentCheck(Segments, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.SEGMENT)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/segments/:segmentId([0-9]+)`}
                        exact={true}
                        component={buildComponentCheck(Segment, [
                            [() => this.accessCheck(AbilityAction.UPDATE, SubjectEntity.SEGMENT)],
                        ])}
                    />
                    <Route
                        path={`${this.matchedPath}/segments/:segmentId([0-9]+)/:tab([\\w]+)`}
                        exact={true}
                        component={buildComponentCheck(SegmentDetails, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.SEGMENT)],
                        ])}
                    />
                </Switch>
            </div>
        )
    }

    protected get webNotImplementedComponent() {
        return () =>
            ErrorPage({
                errorCode: 403,
                hideBackgroundCode: true,
                label: 'This section of the platform is not available for domains that do not implement Web Push.',
            })
    }

    protected get nativeNotImplementedComponent() {
        return () =>
            ErrorPage({
                errorCode: 403,
                hideBackgroundCode: true,
                label: 'This section of the platform is not available for domains that do not implement Native Push.',
            })
    }

    protected get appMessagesNotImplementedComponent() {
        return () =>
            ErrorPage({
                errorCode: 403,
                hideBackgroundCode: true,
                label: 'This section of the platform is not available without the App Message feature enabled. To enable this feature, please contact your Account Manager.',
            })
    }

    protected get appMessageNativeRequiredComponent() {
        const label = (
            <span>
                At least one of the Native Apple or Android&nbsp;
                <a onClick={this.handleSendIntegrationLinkClick}>Send Integrations</a> must be configured and enabled to
                create App Messages.
            </span>
        )
        return () =>
            ErrorPage({
                errorCode: 403,
                hideBackgroundCode: true,
                label,
            })
    }

    protected channelCheck = (channels: DeliveryChannel[], activeOnly: boolean = false) => {
        return channels.some((channel) =>
            getEnabledDeliveryChannels(this.appState.currentDomain!, activeOnly).includes(
                DeliveryChannel.fromString(channel)!,
            ),
        )
    }

    protected flagsCheck = (flags: string[]) => {
        return flags.includes(FEAT_APP_MESSAGES)
    }

    protected accessCheck = <T extends SubjectEntity>(
        action: AbilityAction,
        check: T | ICaslSubject<T> | DomainDto | PlatformUserDomainRecord,
    ) => {
        const isDomainCheck =
            check instanceof DomainDto || check instanceof PlatformUserDomainRecord || check === SubjectEntity.DOMAIN
        const entity = isDomainCheck ? check : this.appState.abilityStore.getDomainOwnedIdentityFor(check as T)

        return this.appState.abilityStore.can(action, entity as ICaslSubject<T> | DomainDto)
    }

    protected handleSendIntegrationLinkClick = () => {
        const historyState = {
            containerId: 'send-integrations',
            returnTo: window.location.pathname,
        }

        this.appService.routeWithinDomain('#settings', false, historyState)
    }
}
