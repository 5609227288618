import * as React from 'react'
import autobind from 'autobind-decorator'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { AsyncButton } from '../../components/async-button/async-button.component'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppService, UserService } from '../../services'
import { AppState } from '../../stores/app'
import './register-preview-device.scss'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Tooltip } from 'antd'
import { MultiSelect } from '../../components/multi-select/multi-select'
import { observe } from 'mobx'
import { arrayUnique, simpleNotification } from '../../_utils/utils'
import { getPushlySdk } from '../../_utils/pushly-sdk'

interface IProps {}

interface IState {
    permission: string
    availableDomains: any[]
    selectedDomains: number[]
    existingSelectedDomains: number[]
}

@observer
export class RegisterPreviewDevice extends BetterComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService
    private userService: UserService
    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.userService = Container.get(UserService)

        this.state = {
            permission: 'default',
            availableDomains: [],
            selectedDomains: [],
            existingSelectedDomains: [],
        }
    }

    public async componentDidMount() {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainsState())]

        this.setDomainsState()
        this.setPermissionState()
    }

    public async setPermissionState(): Promise<void> {
        const sdk = getPushlySdk()
        if (sdk) {
            if (!sdk.context.user) {
                setTimeout(() => this.setPermissionState(), 50)
                return
            }

            const permission = await this.getSdkUserSubStatus()

            if (permission === 'default') {
                sdk.on('permission_denied', () => this.handlePermissionChange('denied'))
                sdk.on('permission_dismissed', () => this.handlePermissionChange('denied'))
                sdk.on('permission_allowed', () => this.handlePermissionChange('subscribed'))
                sdk.PushNotifications.showPermissionPrompt()
            }

            await this.setState({ permission })
        } else {
            simpleNotification('error', 'We are unable to resolve your current Notification permissions.')
        }
    }

    public async setDomainsState(): Promise<void> {
        const domain = this.appState.currentDomain
        const user = this.appState.currentUser
        const userDomains = this.appState.currentUserDomains

        if (user && domain) {
            const userRegistrationDomainIds = (user.puuids || []).map((r) => r.domainId)

            const availableDomains = (userDomains || []).map((d) => ({
                id: d.id,
                name: d.displayName,
            }))

            const selectedDomains = arrayUnique([domain.id, ...userRegistrationDomainIds])

            await this.setState({
                availableDomains,
                selectedDomains,
                existingSelectedDomains: selectedDomains,
            })
        }
    }

    public render() {
        const user = this.appState.currentUser! || {}
        const domain = this.appState.currentDomain! || {}
        const sdk = getPushlySdk()
        const subStatus = !sdk?.context.user ? 'unknown' : this.state.permission

        let message: any = ''

        if (subStatus === 'subscribed') {
            message = (
                <>
                    <p>
                        This device has been successfully linked to {user.name || user.email} for notification previews
                        on {domain.displayName}.
                        <span className="close-notice">
                            {' '}
                            You may now close this window and return to the platform to begin sending previews to this
                            device.
                        </span>
                    </p>

                    <p>
                        To register this device to another domain please update the domain selections below and click
                        "Update Domain Registrations".
                    </p>
                </>
            )
        } else if (subStatus === 'denied') {
            message = (
                <>
                    <p>
                        This device has blocked notification permissions for pushly.com. Please manually reset your
                        browser permissions, reload the page, and accept the requested permission to begin delivering
                        previews to this device.
                    </p>
                </>
            )
        } else {
            message = (
                <>
                    <p>To enable Web Push on this device please accept browser permissions.</p>
                </>
            )
        }

        return this.appState.appIsLoading ? (
            ''
        ) : (
            <div className="register-preview-device">
                <div className="message">
                    <div className="pushly-logo" />

                    {window.PlatformNotifications?.supported ? (
                        message
                    ) : (
                        <div>This page is only valid for devices that support Web Push.</div>
                    )}

                    {subStatus === 'subscribed' && this.state.availableDomains.length > 1 && (
                        <>
                            <Form.Item>
                                <MultiSelect
                                    className="domain-registration-select"
                                    dropdownClassName="domain-registration-options"
                                    options={this.state.availableDomains.map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                        disabled: this.state.existingSelectedDomains.indexOf(d.id) !== -1,
                                    }))}
                                    onChange={this.handleSelectedDomainsChange}
                                    value={this.state.selectedDomains}
                                    selectAllLabel="All Domains"
                                    disableSelectAll={true}
                                    disableSearch={true}
                                    closeOnEscape={true}
                                    maxDisplayCount={5}
                                    maxDisplayFormatter={(options: any[]) => {
                                        const multi = options.length > 1
                                        return (
                                            <Tooltip title={options.map((o) => o.label).join(', ')}>
                                                {options.length} {multi ? 'Domains' : 'Domain'} Selected
                                            </Tooltip>
                                        )
                                    }}
                                />
                            </Form.Item>

                            <AsyncButton type="primary" onClick={this.registerPuuids}>
                                <span>Update Domain Registrations</span>
                            </AsyncButton>
                        </>
                    )}
                </div>
            </div>
        )
    }

    protected async handlePermissionChange(permission: string) {
        await this.setState({ permission })

        if (permission === 'subscribed') {
            this.registerPuuids()
        }
    }

    @autobind
    protected async handleSelectedDomainsChange(selectedDomains: number[]): Promise<void> {
        await this.setState({ selectedDomains })
    }

    @autobind
    protected async registerPuuids(): Promise<void> {
        const sdk = getPushlySdk()
        const sdkUser = sdk?.context.user
        if (!sdkUser) {
            console.error('Unable to register puuids')
            return
        }

        const appUser = this.appState.currentUser!

        const registrations: Array<Promise<any>> = []
        this.state.selectedDomains.forEach((domainId) => {
            registrations.push(this.userService.registerPuuid(appUser.id, domainId, sdkUser.puuid))
        })

        await Promise.all(registrations)
    }

    protected async getSdkUserSubStatus(): Promise<string> {
        return getPushlySdk()?.context.user.subscriberState ?? 'default'
    }
}
