import { Container, Singleton } from 'typescript-ioc'
import { AppService } from './app'
import { IApiResponse } from '@pushly/aqe/lib/interfaces/index'
import * as querystring from 'query-string'
import aqe from '@pushly/aqe'
import { axiosFetch, isAxiosResponseError } from '../config/axios-setup'
import { simpleNotification } from '@pushly/aqe/lib/utils'
import { IApiCallOptions } from '@pushly/aqe/lib/interfaces'
import { FeedNotification } from '@pushly/models/lib/structs/feeds/feed-notification'
import { Feed, MutableFeed } from '@pushly/models/lib/structs/feeds/feed'
import { FeedResponse } from '@pushly/models/lib/structs/feeds/feed-response'

@Singleton
export class FeedService {
    private appService: AppService

    public constructor() {
        this.appService = Container.get(AppService)
    }

    public async fetchFeedById(
        domainId: number,
        feedId: number,
        opts: IApiCallOptions = {},
    ): Promise<{ data: Feed; meta: any; ok: boolean }> {
        let ok = false
        let feed: Feed = new MutableFeed()
        let meta: any

        const serviceURL = `${aqe.defaults.publicApiDomain}/v4/domains/${domainId}/feeds/${feedId}`

        try {
            const res = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts?.cancellationKey,
            )

            feed = res.data.data
            ok = true
            meta = res.data.meta
        } catch (error) {
            if (isAxiosResponseError(error)) {
                simpleNotification('error', error.response?.data.message)
            }
        }
        return { ok, data: feed, meta }
    }

    public async fetchDomainFeeds(domainId: number, opts: any): Promise<IApiResponse<FeedResponse[]>> {
        let ok = false
        let feeds: any[] = []
        let meta: any

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const options = querystring.stringify(opts.query || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v4/domains/${domainId}/feeds?${options}`

        try {
            const req = await axiosFetch('get', {
                url: serviceURL,
            })

            const { data, ...responseMeta } = req.data
            ok = true
            feeds = data.map((feed: FeedResponse) => FeedResponse.parseJsonObject(feed))
            meta = responseMeta
        } catch (error) {
            if (isAxiosResponseError(error)) {
                simpleNotification('error', error.response?.data.message)
            }
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: feeds, meta }
    }

    public async fetchFeedNotifications(
        domainId: number,
        feedId: number,
        opts: IApiCallOptions = {},
    ): Promise<IApiResponse<FeedNotification[]>> {
        let ok = false
        let notifications: FeedNotification[] = []
        let meta: any = {}

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const options = querystring.stringify(opts.query || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v4/domains/${domainId}/feeds/${feedId}/articles?${options}`

        try {
            const res = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts?.cancellationKey,
            )

            ok = true
            const { data, ...responseMeta } = res.data
            console.log('fetchFeedNotifications', data)
            notifications = data.map((responseData: any) => responseData)
            meta = responseMeta
        } catch (error) {
            if (isAxiosResponseError(error)) {
                simpleNotification('error', error.response?.data.message)
            }
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: notifications, meta }
    }
}
