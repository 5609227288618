import React, { PropsWithChildren } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(STRIPE_API_KEY)

export const InjectedStripeForm = (props: PropsWithChildren<any>) => {
    return <Elements stripe={stripePromise}>{props.children}</Elements>
}

import { STRIPE_API_KEY } from '../../constants'
