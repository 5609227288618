import * as React from 'react'
import * as randomstring from 'randomstring'
import fileDownload from 'js-file-download'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { AppState } from '../stores/app'
import { axiosFetch, AxiosMethod } from '../config/axios-setup'
import { IReportConfig } from '../features/reporting_v2/interfaces/report-config'
import { simpleNotification } from '../_utils/utils'
import * as querystring from 'querystring'
import aqe from '@pushly/aqe'
import { SavedReportScheduleModel } from '../models/saved-reports/saved-report-schedule.model'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

// TODO: update "any" response typings to use a SavedReport type

@Singleton
export class InsightsService {
    private appState: AppState
    private appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public getBaseInsightsUrl(nonDomainRequest: boolean = false): string {
        const domainId = this.appState.currentDomain?.id
        if (String(process.env.LOCAL_ENV) === 'true') {
            return nonDomainRequest
                ? aqe.defaults.insightsDomain
                : `${aqe.defaults.insightsDomain}/domains/${domainId}/insights`
        } else {
            return nonDomainRequest
                ? `${aqe.defaults.publicApiDomain}/insights`
                : `${aqe.defaults.publicApiDomain}/domains/${domainId}/insights`
        }
    }

    public async fetch(
        contract: any,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
        nonDomainRequest?: boolean,
        scopedInsightsPath?: string,
    ): Promise<any | undefined> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: scopedInsightsPath
                        ? `${aqe.defaults.publicApiDomain}/${scopedInsightsPath.replace(/^\//, '')}`
                        : this.getBaseInsightsUrl(nonDomainRequest),
                    data: contract,
                },
                cancellationKey,
            )

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }

            const responseIsCSV = /text\/csv/i.test(req.request.getResponseHeader('content-type'))
            if (responseIsCSV) {
                fileDownload(req.data, `${contract.filename || 'pushly-insights'}.csv`)
            } else if ('reportUrl' in req.data || 'report_url' in req.data) {
                const reportUrl = req.data.reportUrl || req.data.report_url
                const fileReq = await axiosFetch(
                    'get',
                    {
                        url: reportUrl,
                        config: { external: true },
                    },
                    cancellationKey,
                )

                return fileReq.data.data
            } else {
                return req.data.data
            }
        } catch (error) {
            handleResponseErrorMessage(error)
            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
        }
    }

    public async getSavedReports(
        domainId: number,
        userId: number,
        showLoadingScreen?: boolean,
        opts?: any,
        cancellationKey?: string,
    ): Promise<any | undefined> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const options = querystring.stringify(opts || '')

        let reports: any[] | undefined
        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/users/${userId}/saved-reports?${options}`,
                },
                cancellationKey,
            )

            reports = req.data.data || []
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return reports
    }

    public async destroySavedReport(
        domainId: number,
        reportId: number,
        userId: number,
        showLoadingScreen?: boolean,
    ): Promise<boolean> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        let archived = false
        try {
            await axiosFetch('delete', {
                url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/users/${userId}/saved-reports/${reportId}`,
            })

            archived = true
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return archived
    }

    public async generateSharedReport(
        domainId: number,
        reportConfig: IReportConfig & { name?: string },
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<{ id: number } | undefined> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        let report: { id: number } | undefined
        try {
            const url = `${aqe.defaults.publicApiDomain}/domains/${domainId}/saved-reports`

            const req = await axiosFetch(
                'post',
                {
                    url,
                    data: {
                        name: randomstring.generate(),
                        ...reportConfig,
                    },
                },
                cancellationKey,
            )

            report = req.data.data
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return report
    }

    public async loadReportFromLink(
        domainId: number,
        reportId: number,
        shared: boolean = true,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<any | undefined> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        let report: any
        try {
            let url = `${aqe.defaults.publicApiDomain}/domains/${domainId}/saved-reports/${reportId}`
            if (!shared) {
                const userId = this.appState.currentUser!.id
                url = `${aqe.defaults.publicApiDomain}/domains/${domainId}/users/${userId}/saved-reports/${reportId}`
            }

            const req = await axiosFetch(
                'get',
                {
                    url,
                },
                cancellationKey,
            )

            report = req.data.data
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return report
    }

    public async save(
        domainId: number,
        userId: number,
        reportId: number | undefined,
        name: string,
        isDefault: boolean,
        reportConfig: IReportConfig & { name?: string },
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<any | undefined> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        let report: any
        try {
            let method: AxiosMethod = 'post'
            let url = `${aqe.defaults.publicApiDomain}/domains/${domainId}/users/${userId}/saved-reports`

            if (reportId) {
                method = 'patch'
                url = `${url}/${reportId}`
            }

            const req = await axiosFetch(
                method,
                {
                    url,
                    data: {
                        name,
                        isDefault,
                        ...reportConfig,
                    },
                },
                cancellationKey,
            )

            report = req.data.data
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return report
    }

    public async saveSavedReportSchedule(
        schedule: SavedReportScheduleModel,
        domainId: number,
        userId: number,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<boolean> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const mode = schedule.getId() ? 'updated' : 'created'

        let saved = false
        try {
            let url = `${aqe.defaults.publicApiDomain}/domains/${domainId}/users/${userId}/saved-reports/scheduled`
            await axiosFetch(
                'post',
                {
                    url,
                    data: schedule,
                },
                cancellationKey,
            )

            saved = true
            simpleNotification('success', `The saved report delivery schedule was successfully ${mode}`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return saved
    }

    public async destroySavedReportSchedule(
        scheduleId: number,
        domainId: number,
        userId: number,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<boolean> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        let destroyed = false
        try {
            let url = `${aqe.defaults.publicApiDomain}/domains/${domainId}/users/${userId}/saved-reports/scheduled/${scheduleId}`

            await axiosFetch(
                'delete',
                {
                    url,
                },
                cancellationKey,
            )

            destroyed = true
            simpleNotification('success', 'the schedule was successfully deleted')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return destroyed
    }
}
