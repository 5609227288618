import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { PieChartOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

export const NotificationHoldOutBadge: React.StatelessComponent<IBadgeProps> = (props) => {
    const badge = (
        <span className="icon-pie-chart">
            <PieChartOutlined />
        </span>
    )

    return (
        <span className="notification-hold-out-badge">
            {props.hideTooltip ? (
                badge
            ) : (
                <Tooltip
                    title={`This notification was marked as a “Hold Out” variant in the A/B test.
                    It will receive no impressions or clicks.`}
                >
                    {badge}
                </Tooltip>
            )}
        </span>
    )
}
