import aqe from '@pushly/aqe'
import axios, { Canceler, AxiosRequestConfig, AxiosError } from 'axios'
import React from 'react'
import { convertCase, shouldUseLegacyAuthHeader } from '../_utils/utils'
import { APP_BASE_ROUTE, APPLICATION_VERSION } from '../constants'
import { ApiCallOptions } from '@pushly/aquarium-utils/lib/react'
import { notification } from 'antd'
import { Container } from 'typescript-ioc/es5'
import { AuthService } from '../services/index'
import { AppState } from '../stores/app'
import { PushlyCookie } from '../enums/pushly-cookie.enum'

export function isLocalEnv() {
    return process.env.LOCAL_ENV?.toString() === 'true'
}

function platformRequestInterceptor(request: any) {
    if (request.external === true) {
        return request
    }

    request.withCredentials = true
    request.headers['X-Application-Version'] = APPLICATION_VERSION
    request.headers['X-Application-Source'] = 'pufferfish'

    if (shouldUseLegacyAuthHeader()) {
        request.headers.Authorization = `Bearer ${window.localStorage.getItem(PushlyCookie.PLATFORM_COOKIE)}`
    }

    if (request.data) {
        request.data = convertCase(request.data, 'snake')
    }

    return request
}

function platformResponseInterceptor(response: any) {
    const resData = response.data ?? {}

    if (resData.status === 'success') {
        response.data.data = convertCase(response.data.data, 'camel')
    }

    return response
}

function platformReponseErrorInterceptor(error: any) {
    const errorRes = error.response

    if (errorRes) {
        const resConfig: any = errorRes.config ?? {}
        const errorType: string = errorRes.data?.error_type ?? 'unknown'

        const appState: AppState = Container.get(AppState)
        const authSvc: AuthService = Container.get(AuthService)

        if (!resConfig.bypassGlobalErrorHandlers) {
            if (errorRes.status === 401 && /auth_token_(expired|missing)/i.test(errorType)) {
                if (!isLocalEnv() && appState?.isAuthenticated) {
                    authSvc.logout(true)
                }
            }
            if (errorRes.status === 429) {
                const errMsg = errorRes.data?.message ?? 'Please reduce your request rate.'
                const notifDescription = `Pushly was unable to complete your request. ${errMsg.replace(/\.$/, '')}.`

                notification.error({
                    key: 'rate-limit-exception',
                    className: 'simple-notification',
                    message: undefined,
                    description: notifDescription,
                    duration: 30,
                })
            }
        }
    }

    throw error
}

export const setupAxios = () => {
    axios.interceptors.request.use(platformRequestInterceptor)
    aqe.useAxiosInterceptor('request', platformRequestInterceptor)

    // pass through for success
    // implement global error handlers
    axios.interceptors.response.use(platformResponseInterceptor, platformReponseErrorInterceptor)
    aqe.useAxiosInterceptor('response', platformResponseInterceptor, platformReponseErrorInterceptor)
}

export const axiosCancellationRequests: Record<string, Canceler> = {}
export const isAxiosCancellation = (error: any) => axios.isCancel(error)
export const isAxiosResponseError = (error: Error): error is AxiosError => !!(error as AxiosError).response

export type AxiosMethod = 'get' | 'put' | 'post' | 'patch' | 'delete'
interface IAxiosRequest {
    url: string
    data?: any
    config?: (AxiosRequestConfig & { external?: boolean }) | undefined
}

export async function axiosFetch(method: AxiosMethod, pack: IAxiosRequest, cancellationKey?: string): Promise<any> {
    pack.config = pack.config || {}
    const args: any[] = [pack.url]
    if (pack.data) args.push(pack.data)

    if (cancellationKey) {
        pack.config.cancelToken = await buildCancellationRequest(cancellationKey)
    }

    args.push(pack.config)
    const response = await axios[method.toLowerCase()](...args)

    if (cancellationKey && axiosCancellationRequests[cancellationKey]) {
        delete axiosCancellationRequests[cancellationKey]
    }

    return response
}

const buildCancellationRequest = async (cancellationKey: string): Promise<any> => {
    const handler = axiosCancellationRequests[cancellationKey]

    if (handler) {
        await handler()
        delete axiosCancellationRequests[cancellationKey]
    }

    return new axios.CancelToken((cb) => {
        axiosCancellationRequests[cancellationKey] = cb
    })
}

export const convertCancellationKeyToToken = async (opts?: ApiCallOptions): Promise<void> => {
    if (!opts?.cancelToken && !!opts?.cancellationKey) {
        opts.cancelToken = await buildCancellationRequest(opts.cancellationKey)
        delete opts.cancellationKey
    }
}

export const cancelNamedAxiosRequest = (key: string) => {
    if (key in axiosCancellationRequests) {
        axiosCancellationRequests[key]()
    }
}
